import React, { useContext } from 'react'
import { graphql, useStaticQuery, navigate } from 'gatsby'
import classes from './Posts.module.scss'
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../../../context/GlobalContextProvider'
import Grid from '@material-ui/core/Grid'
import Button from '../../../components/UI/Button/Button'

const Posts = props => {
  const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext)
  const data = useStaticQuery(graphql`
    query {
      german: allDirectusPostsTranslation(filter: { language: { eq: "de" } }) {
        edges {
          node {
            post_details
            topic
            title
            id
            language
            slug
            post_image {
              data {
                full_url
              }
            }
          }
        }
      }
      polish: allDirectusPostsTranslation(filter: { language: { eq: "pl" } }) {
        edges {
          node {
            post_details
            topic
            title
            id
            language
            slug
            post_image {
              data {
                full_url
              }
            }
          }
        }
      }
      english: allDirectusPostsTranslation(filter: { language: { eq: "en" } }) {
        edges {
          node {
            post_details
            topic
            title
            id
            language
            slug
            post_image {
              data {
                full_url
              }
            }
          }
        }
      }
    }
  `)
  let edges = null
  if (state.lang === 'pl-PL') {
    edges = data.polish.edges
  } else if (state.lang === 'de-DE') {
    edges = data.german.edges
  } else {
    edges = data.english.edges
  }

  const items = edges.slice(0, state.posts).map(post => (
    <Grid
      item
      xs={12}
      sm={6}
      md={4}
      className={classes.postGridContainer}
      key={post.node.id}
    >
      <div
        tabIndex={0}
        className={classes.postBox}
        onClick={() => navigate(`${post.node.language}/blog/${post.node.slug}`)}
        onKeyDown={() =>
          navigate(`${post.node.language}/blog/${post.node.slug}`)
        }
      >
        <div
          className={classes.postImage}
          style={{
            backgroundImage: `url(${post.node.post_image.data.full_url})`
              ? `url(${post.node.post_image.data.full_url})`
              : null,
          }}
        ></div>
        <div className={classes.postDetails}>
          <div className={classes.title}>
            <p>{post.node.title}</p>
          </div>
        </div>
      </div>
    </Grid>
  ))
  const itemsLength = items.length
  const postsLength = edges.length
  let button = (
    <Button
      onClick={() => dispatch({ type: 'TOGGLE_POSTS' })}
      style={{ marginBottom: 20 }}
    >
      Więcej
    </Button>
  )
  if (itemsLength === postsLength) {
    button = null
  }
  return (
    <>
      <Grid container className={classes.posts}>
        {items}
      </Grid>
      {button}
    </>
  )
}

export default Posts
