import React from 'react'
import { withNamespaces } from 'react-i18next'
import '../../i18n'

import Layout from '../../hoc/layout/layout'
import SEO from '../../hoc/seo'
import BlogHeader from './BlogHeader/BlogHeader'
import Posts from './Posts/Posts'

const Blog = ({ t }) => {
  return (
    <Layout>
      <SEO title={t('blog')} />
      <BlogHeader />
      <Posts />
    </Layout>
  )
}

export default withNamespaces()(Blog)
